
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserOnline, exportUserOnline } from "@/api/request/bigdata";

//组件
@Component({
  name: "OnlineTime",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;
  private totalInfo: any = { online_total: 0, active_total: 0, average: 0 };

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: [],

    //请求数据
    end_time: "",
    start_time: "",
    is_blogger: -1,
    is_man: undefined,
    user_id: undefined,

    //是否Excel
    excel: false,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //性别
  private sexOptions: any[] = [
    { value: 1, label: "男" },
    { value: 2, label: "女" },
  ];

  //是否博主
  private bloggerOptions: any[] = [
    { value: -1, label: "全部" },
    { value: 0, label: "用户" },
    { value: 1, label: "博主" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await getUserOnline(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.totalInfo.average = data.average;
    this.totalInfo.online_total = data.online_total;
    this.totalInfo.active_total = data.active_total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getYesterday()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD7()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
  }

  //处理导出
  private async handleExcel() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;

    //获取数据
    const { data } = await exportUserOnline(this.listQuery);

    //保存文件
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}在线时长`) : saveAs(data, "在线时长总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //重置数据
    this.listQuery.is_blogger = -1;
    this.listQuery.is_man = undefined;
    this.listQuery.user_id = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //时间处理
  private secondsToTime(seconds: any): string {
    //数据赋值
    const remainingSeconds = seconds % 60;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    //返回结果
    return `${formattedHours}小时${formattedMinutes}分钟${formattedSeconds}秒`;
  }
}
