import { render, staticRenderFns } from "./online_time.vue?vue&type=template&id=1894e459&scoped=true"
import script from "./online_time.vue?vue&type=script&lang=ts"
export * from "./online_time.vue?vue&type=script&lang=ts"
import style0 from "./online_time.vue?vue&type=style&index=0&id=1894e459&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1894e459",
  null
  
)

export default component.exports